import React, { useEffect, useRef, useState } from "react";

import "@shopify/polaris/build/esm/styles.css";

//@ts-ignore
import { useFilePicker } from "react-sage";
import { Modal, Banner, Spinner } from "@shopify/polaris";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

function ArtworkSection({
  selectedProduct,
  handleImageFileChange,
  artworkImage,
  croppedArtworkImage: processedArtworkImage,
  setCroppedArtworkImage,
  loadingArtworkImage,
}) {
  // eslint-disable-next-line
  const { files, onClick, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: 1,
    // maxImageWidth: 1000,
    // imageQuality: 0.92,
    resizeImage: false,
  });
  useEffect(() => {
    if (files && files.length > 0) {
      const fileObject = files[0];
      handleImageFileChange(fileObject);
    }
    // eslint-disable-next-line
  }, [files]);

  const cropperRef = useRef<HTMLImageElement>(null);
  const [cropperActive, setCropperActive] = useState(false);

  return (
    <>
      <Modal
        title="Crop image to artwork only"
        open={cropperActive}
        onClose={() => {
          setCropperActive(false);
        }}
        secondaryActions={[
          {
            content: "Revert to original",
            onAction: () => {
              setCroppedArtworkImage(artworkImage);
              setCropperActive(false);
            },
          },
        ]}
        primaryAction={{
          content: "Crop image",
          onAction: () => {
            const imageElement: any = cropperRef?.current;
            const cropper: any = imageElement?.cropper;
            setCroppedArtworkImage(cropper.getCroppedCanvas().toDataURL());
            setCropperActive(false);
          },
        }}
      >
        <Cropper
          src={artworkImage}
          style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={16 / 9}
          // autoCropArea={1.0}
          viewMode={1}
          guides={false}
          crop={() => {
            // const imageElement: any = cropperRef?.current;
            // const cropper: any = imageElement?.cropper;
            // setArtworkImage(cropper.getCroppedCanvas().toDataURL());
            // setCropperActive(false);
          }}
          ref={cropperRef}
        />
      </Modal>
      <HiddenFileInput accept="image/*" multiple={false} />
      <>
        {selectedProduct &&
        !selectedProduct.images[0] &&
        !processedArtworkImage &&
        !loadingArtworkImage ? (
          <>
            <Banner
              status="warning"
              title="No image found"
              action={{
                content: "Select an image",
                onAction: () => onClick(),
              }}
            >
              Please select an image of the artwork from your computer
            </Banner>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                padding: 0,
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#B0B0B0",
                width: "100%",
                height: "120px",
              }}
            >
              {selectedProduct && loadingArtworkImage ? (
                <div style={{ margin: "auto", maxHeight: "100%" }}>
                  <Spinner
                    accessibilityLabel="Artwork image is loading"
                    size="large"
                  />
                </div>
              ) : (
                <img
                  src={processedArtworkImage}
                  alt=""
                  style={{
                    objectFit: "scale-down",
                    marginLeft: "auto",
                    marginRight: "auto",
                    // backgroundColor: "red",
                    // background: "url(" + artworkImage + ") no-repeat",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    // backgroundSize: "contain",
                    // backgroundPosition: "center",
                    borderWidth: processedArtworkImage ? 1.2 : 0,
                    borderStyle: "dashed",
                    borderColor: "rgba(0, 0, 0, 0.4)",
                    opacity: loadingArtworkImage ? 0.5 : 1.0,
                  }}
                />
              )}
            </div>
            <button
              className="Polaris-Button"
              style={{
                float: "left",
                marginTop: "-6.0em",
                marginLeft: 6,
                paddingLeft: 9,
                height: "2.8em",
                width: "2.8em",
                borderRadius: "7px 7px 0px 0px",
                borderWidth: "0.5px",
                borderStyle: "solid",
                borderColor: "#CED1D3",
                // boxShadow: "0 2px rgba(0, 0, 0, 0.04)",
              }}
              onClick={(e) => {
                e.currentTarget.blur();
                setCropperActive(true);
              }}
            >
              <img src="./icon_crop.svg" alt="" />
            </button>
            <button
              className="Polaris-Button"
              style={{
                float: "left",
                marginTop: "-3.25em",
                marginLeft: 6,
                paddingLeft: 9,
                height: "2.8em",
                width: "2.8em",
                borderRadius: "0px 0px 7px 7px",
                borderWidth: "0.5px",
                borderStyle: "solid",
                borderColor: "#CED1D3",
                boxShadow: "0 2px rgba(0, 0, 0, 0.04)",
              }}
              onClick={(e) => {
                e.currentTarget.blur();
                onClick();
              }}
            >
              <img src="./icon_image_major.svg" alt="" />
            </button>
          </>
        )}
      </>
    </>
  );
}

export default ArtworkSection;
